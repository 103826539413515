import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Blog = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="author" content="Alp Kahvecioglu"></meta>
        <meta
          name="description"
          content="enpassant blog - useful coding bits, random knowledge"
        ></meta>
        <title>alp kahvecioglu -- blog</title>
        <link rel="canonical" href="https://enpassant.me/blog" />
        <html lang="en" />
      </Helmet>
      <Header />
      <p>There will be a blog here soon.</p>
      <Footer />
    </div>
  )
}

export default Blog
